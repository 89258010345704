@import '../../style/style.sass'
    
.servises
    margin: 20px 10%
.tabs
    &__img
        width: 100%
        height: 60vh
        object-fit: cover    
        margin-bottom: 10px
    &__item
        a
            color: $gray-color
            &:hover
                color: $gray-color
        .active
            background-color: $main-color!important
.tab-pane
        p
            text-align: justify
            margin-bottom: 0

@media (max-width: 992px)
    .servises
        margin: 20px 5%
    .tabs
        &__item
            font-size: 15px
            line-height: 18px
        &__col
            padding: 0 7px
    
@media (max-width: 767px)
    .tab-pane
        p
            font-size: 15px
            line-height: 18px