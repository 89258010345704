@import '../../style/style.sass'
    
.vacancy
    margin: 0 auto
    flex: 1
    color: $gray-color
    &__photo
        width: 80%
        display: block
        margin: 0 auto
    &__title
        margin-top: 20px
        margin-bottom: 3px
        font-size: 18px
        line-height: 1.2
        font-weight: 700
        text-align: center