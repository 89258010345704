.modal-project
    display: grid
    grid-template-columns: 54% 42%
    gap: 4%
    margin-top: 15px
    &__info
        display: grid
        grid-template-columns: 40% 60%
        font-size: 14px
        column-gap: 2px
        &_1
            display: flex
            align-items: center
            background-color: #e3e7d9
            padding-left: 10px
        &_2
            display: flex
            align-items: center
            background-color: #fff
            padding-left: 10px
        &_list
            list-style-type: none
            padding-left: 0
            margin-bottom: 0

@media (max-width: 992px)
    .modal-project
        grid-template-columns: 100%
        gap: 20px

@media (max-width: 425px)
    .modal-project
        &__info
            font-size: 12px
            &_1, &_2
                padding-left: 0