.project
    &__container
        width: 100%
        display: grid
        grid-template-columns: repeat(auto-fit, 200px)
        gap: 60px
        justify-content: start
    &__item
        width: 200px
        height: 200px
        cursor: pointer
        &:hover
            filter: saturate(300%)
            font-weight: bold
        &_photo
            width: 100%
            height: 85%
            object-fit: cover
        &_name
            margin-top: 5px
            font-size: 13px
            line-height: 15px
            text-align: center