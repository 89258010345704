@import '../../style/style.sass'
    
.description
    margin: 20px 10%
    &__text, &__list
        font-size: 16px
        line-height: 18px
        text-align: justify
    &__title
        margin-top: 20px
        margin-bottom: 3px
        font-size: 18px
        line-height: 1.2
        font-weight: 700
    &__list
        padding-left: 30px
        line-height: 1.5
    &__link
        color: black
        text-decoration: none
        &:hover
            color: $main-color
            font-weight: bold            
        img
            height: 50px
            margin-top: 10px
    &__license
        height: 100px
        cursor: pointer
        &_large
            width: 100%

@media (max-width: 576px)
    .description
        margin: 20px 5%

@media (max-width: 425px)
    .description
        &__title
            font-size: 16px
        &__text, &__list
            font-size: 14px
        &__link
            display: flex
            flex-direction: column
            font-size: 14px