@import '../../style/style.sass'
    
.blank
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 100vh
    &_title
        text-align: center
        color: $main-color
        font-weight: bold
    &_text
        text-align: center
        color: $gray-color
