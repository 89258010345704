.slide-item
    display: block
    margin: 0 auto
    width: 90%
    height: 35vh
    object-fit: cover
    &__title
        text-shadow: #000 2px 2px 1px
        margin-bottom: 0
        padding: 0

@media (max-width: 425px)
    .slide-item
        &__title
            font-size: 18px