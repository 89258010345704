.vacancy-card
    flex-direction: row
    margin-top: 15px
    padding: 10px
    font-size: 18px
    line-height: 35px
    cursor: pointer

@media (max-width: 991px)
    .vacancy-card
        font-size: 16px
        line-height: 26px

@media (max-width: 425px)
    .vacancy-card
        margin-top: 10px
        font-size: 14px
        line-height: 18px