@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css')
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css')
@import './custom'

$gray-color: #747980
$main-color: teal

*
    box-sizing: border-box
    margin: 0 
    padding: 0
    font-family: 'Arial'

#root
    display: flex
    min-height: 100vh
    flex-direction: column

body
    overflow-y: scroll