.slider
    margin: 0
    background-color: white
    height: 35vh
    &__container
        display: flex
        padding: 0 20px
    &__slide
        height: 35vh        
        margin-right: 10px
        color: #ffff
        flex: 1        
        position: relative
        transition: all 1s 
        img
            height: 35vh
            width: 100%
            object-fit: cover
            border-radius: 10px
        &.active
            flex: 12
        h3
            display: none
            position: absolute
            font-size: 20px
            bottom: 20px
            left: 20px
            margin: 0
        &.active h3 
            display: block
            text-shadow: #000 2px 2px 1px
        .btn-remove
            display: none
            position: absolute
            top: 20px
            left: 20px
        &.active .btn-remove
            display: block
    &__carousel
        display: none

@media (max-width: 767px)
    .slider
        &__container
            display: none
        &__carousel
            display: block