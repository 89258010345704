.systems
    &__list
        margin-top: 20px
        padding-left: 0
        color: grey
        text-align: justify
        list-style-type: none

@media (max-width: 575px)
    .systems
        &__list
            margin-bottom: 10px
            font-size: 14px