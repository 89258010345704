.slider-item
    display: block
    margin: 0 auto
    width: 100%
    height: 400px

@media (max-width: 1199px)
    .slider-item
        height: 300px

@media (max-width: 575px)
    .slider-item
        height: 250px

@media (max-width: 425px)
    .slider-item
        height: 200px